@import url(https://fonts.googleapis.com/css2?family=VT323&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



body > iframe {
  pointer-events: none;
}

.App {
  text-align: center;
  background-color: rgb(147, 107, 114);

  position: fixed;
  width: 100%;
  height: 100%;
}

.console {
  pointer-events: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.spiky-dj-image {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  z-index: 1;
}

.num-minted {
  font-family: 'VT323', monospace;
  font-weight: normal;
  opacity: 0.8;

  z-index: 2;

  position: absolute;
  text-align: center;
}

.manifesto-container {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  z-index: 1;
  overflow: hidden;
  background-color: white;

  -webkit-animation: fade-in 1s linear;

          animation: fade-in 1s linear;
}

@-webkit-keyframes fade-in {
  from {
    opacity: 0.0;
  }
  to {
    opacity: 1.0;
  }
}

@keyframes fade-in {
  from {
    opacity: 0.0;
  }
  to {
    opacity: 1.0;
  }
}


.manifesto-text {
  font-family: 'VT323', monospace;
  font-size: 18px;
  opacity: 0.6;

  height: -webkit-fit-content;

  height: -moz-fit-content;

  height: fit-content;
  text-align: center;
}

.manifesto-btn {
  background-image: url(/static/media/manifesto.271d6764.webp);

  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;

  z-index: 2;
  
  border: none;

  background-color: transparent;
}

.connect-btn {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;

  z-index: 2;

  background-color: transparent;
  border: none;
  background-image: url(/static/media/connect.24be8d51.webp);
}

.connected {
  background-image: url(/static/media/connected.423a96fb.webp);
}

.mint-1-btn {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;

  z-index: 2;

  background-color: transparent;
  border: none;
  background-image: url(/static/media/mint_1.7c18cb01.webp);
}

.minted-1 {
  background-image: url(/static/media/minted_1.d02667a7.webp);
}

.mint-5-btn {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;

  z-index: 2;

  background-color: transparent;
  border: none;
  background-image: url(/static/media/mint_5.00f339cd.webp);
}

.minted-5 {
  background-image: url(/static/media/minted_5.d16c6632.webp);
}

.mint-15-btn {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;

  z-index: 2;

  background-color: transparent;
  border: none;
  background-image: url(/static/media/mint_15.65ce62ee.webp);
}

.minted-15 {
  background-image: url(/static/media/minted_15.84e04098.webp);
}

.mint-30-btn {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;

  z-index: 2;

  background-color: transparent;
  border: none;
  background-image: url(/static/media/mint_30.6b3d32d0.webp);
}

.minted-30 {
  background-image: url(/static/media/minted_30.3918b985.webp);
}

.play-btn {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;

  z-index: 2;

  background-color: transparent;
  border: none;
  background-image: url(/static/media/play.65a8552d.webp);
}

.playing {
  background-image: url(/static/media/played.16d700d4.webp);
}

.pause-btn {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;

  z-index: 2;

  background-color: transparent;
  border: none;
  background-image: url(/static/media/pause.e3289633.webp);
}

.paused {
  background-image: url(/static/media/paused.4b71d4ad.webp);
}

.stop-btn {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;

  z-index: 2;

  background-color: transparent;
  border: none;
  background-image: url(/static/media/stop.bf83e768.webp);
}

.stopped {
  background-image: url(/static/media/stopped.b997ea7b.webp);
}

.skip-fwd-btn {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;

  z-index: 2;

  background-color: transparent;
  border: none;
  background-image: url(/static/media/skip_forward.9add17fb.webp);
}

.skipped-fwd {
  background-image: url(/static/media/skipped_forward.9cd6f652.webp); 
}

.skip-back-btn {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;

  z-index: 2;

  background-color: transparent;
  border: none;
  background-image: url(/static/media/skip_back.0443b193.webp);
}

.skipped-back {
  background-image: url(/static/media/skipped_back.1f868a3d.webp);
}

.spiky-dj-text {
  background-image: url(/static/media/spiky_DJ_txt.48658aa9.webp);

  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;

  z-index: 2;

  background-color: transparent;
}

.minting-zone {
  background-image: url(/static/media/minting_zone_txt.7bc5bb72.webp);

  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;

  z-index: 2;

  background-color: transparent;
}

.mint-1-txt {
  background-image: url(/static/media/mint_1_txt.2aee2449.webp);

  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;

  z-index: 2;

  background-color: transparent;
}

.mint-5-txt {
  background-image: url(/static/media/mint_5_txt.8c5833b7.webp);

  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;

  z-index: 2;

  background-color: transparent;
}

.mint-15-txt {
  background-image: url(/static/media/mint_15_txt.f80c1d67.webp);

  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;

  z-index: 2;

  background-color: transparent;
}

.mint-30-txt {
  background-image: url(/static/media/mint_30_txt.ae699bb9.webp);

  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;

  z-index: 2;

  background-color: transparent;
}

.console-text {
  font-family: 'VT323', monospace;
  font-size: 25px;
  font-weight: normal;
  opacity: 0.6;

  position: absolute;
  text-align: center;
  -webkit-transform: scale(1.5, 2.0);
          transform: scale(1.5, 2.0);
}

.twitter-link {
  background-image: url(/static/media/twitter.3ad29c4d.webp);

  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;

  z-index: 2;

  border: none;

  background-color: transparent;
}

/* mobile */
@media only screen and (max-width: 480px) {

  .connect-btn {
    width: 15%;
    height: 5%;

    left: 81%;
    top: 3%;
  }

  .console-text {
    font-size: 12px;

    left: 30%;
    top: 93%;  
  }

  .num-minted {
    font-size: 7.5px;

    width: 10%;

    -webkit-transform: scale(1.5, 3.0);

            transform: scale(1.5, 3.0);

    line-height: 80%;
    text-align: center;

    left: 64.5%;
    top: 4.5%;
  }

  .manifesto-text {
    /* scroll text anim */
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    -webkit-animation: scroll-text-anim 110s linear infinite;
    animation: scroll-text-anim 110s linear infinite;

    padding-left: 15%;
    padding-right: 15%;
  }
  
  /* for Chrome */
  @-webkit-keyframes scroll-text-anim {
    from { -webkit-transform: translateY(8%); }
    to { -webkit-transform: translateY(-100%); }
  }
  
  @keyframes scroll-text-anim {
    from {
      -webkit-transform: translateY(8%);
      transform: translateY(8%);
    }
    to {
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%);
    }
  }

  .manifesto-btn {
    width: 13%;
    height: 3.3%;

    left: 48%;
    top: 3.8%;
  }

  .twitter-link {
    width: 10%;
    height: 5%;
  
    left: 48%;
    top: 43.5%;  
  }

  .minting-zone {
    width: 28%;
    height: 3%;

    left: 37.5%;
    top: 52.8%;
  }

  .mint-1-btn {
    width: 18%;
    height: 7%;

    left: 28%;
    top: 77%;
  }

  .mint-5-btn {
    width: 18%;
    height: 7%;

    left: 28%;
    top: 85%;
  }

  .mint-15-btn {
    width: 18%;
    height: 7%;

    left: 73%;
    top: 77%;
  }

  .mint-30-btn {
    width: 18%;
    height: 7%;

    left: 73%;
    top: 85%;
  }

  .mint-1-txt {
    width: 20%;
    height: 5%;

    left: 6%;
    top: 78%;
  }
  
  .mint-5-txt {
    width: 20%;
    height: 5%;

    left: 6%;
    top: 86%;
  }
  
  .mint-15-txt {
    width: 20%;
    height: 5%;

    left: 52%;
    top: 78%;
  }
  
  .mint-30-txt {
    width: 20%;
    height: 5%;

    left: 52%;
    top: 86%;
  }

  .play-btn {
    width: 5%;
    height: 2.5%;

    left: 17%;
    top: 44.7%;
  }

  .pause-btn {
    width: 5%;
    height: 2.5%;
  
    left: 24.5%;
    top: 44.7%;  
  }

  .stop-btn {
    width: 5%;
    height: 2.5%;
  
    left: 32%;
    top: 44.7%;  
  }

  .skip-fwd-btn {
    width: 5%;
    height: 2.5%;
  
    left: 39.5%;
    top: 44.7%;  
  }

  .skip-back-btn {
    width: 5%;
    height: 2.5%;
  
    left: 10.3%;
    top: 44.7%;  
  }

  .spiky-dj-image {
    background-image: url(/static/media/spiky_dj_image.abe6670f.webp);

    width: 100%;
    height: 50%;
    top: 10%;
    left: 8%;  
  }  

  .manifesto-container {
    width: 100%;
    height: 50%;
    top: 10%;
    left: 3%;  
  }  

  .spiky-dj-text {
    width: 10%;
    height: 3%;
    top: 4%;
    left: 34.5%;    
  }

  .console {
    background-image: url(/static/media/console.d7edad83.webp);
  }
}

/* dekstop */ 
@media only screen and (min-width: 480px) {

  .connect-btn {
    width: 10%;
    height: 8%;

    left: 88.5%;
    top: 3%;
  }

  .console-text {
    left: 50%;
    top: 91.5%;  
  }

  .num-minted {
    font-size: 20px;

    left: 66.2%;
    top: 5.8%;

    -webkit-transform: scale(1.5, 4.0);

            transform: scale(1.5, 4.0);
  }

  .manifesto-text {
    /* scroll text anim */
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
    -webkit-animation: scroll-text-anim 80s linear infinite;
    animation: scroll-text-anim 80s linear infinite;

    font-size: 30px;

    padding-left: 8%;
    padding-right: 8%;
  }
  
  /* for Chrome */
  @-webkit-keyframes scroll-text-anim {
    from { -webkit-transform: translateY(23%); }
    to { -webkit-transform: translateY(-100%); }
  }
  
  @keyframes scroll-text-anim {
    from {
      -webkit-transform: translateY(23%);
      transform: translateY(23%);
    }
    to {
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%);
    }
  }

  .manifesto-btn {
    width: 13%;
    height: 5.3%;

    left: 48.7%;
    top: 3.8%;
  }

  .twitter-link {
    width: 10%;
    height: 8%;
  
    left: 48.5%;
    top: 60%;  
  }

  .minting-zone {
    width: 27%;
    height: 5%;

    left: 39%;
    top: 72.5%;
  }

  .mint-1-btn {
    width: 13%;
    height: 5%;

    left: 45%;
    top: 80%;
  }

  .mint-5-btn {
    width: 13%;
    height: 5%;

    left: 45%;
    top: 86%;
  }

  .mint-15-btn {
    width: 13%;
    height: 5%;

    left: 75%;
    top: 80%;
  }

  .mint-30-btn {
    width: 13%;
    height: 5%;

    left: 75%;
    top: 86%;
  }

  .mint-1-txt {
    width: 14%;
    height: 5%;

    left: 30.5%;
    top: 80%;
  }
  
  .mint-5-txt {
    width: 14%;
    height: 5%;

    left: 30.5%;
    top: 86%;
  }
  
  .mint-15-txt {
    width: 14%;
    height: 5%;

    left: 60%;
    top: 80%;
  }
  
  .mint-30-txt {
    width: 14%;
    height: 5%;

    left: 60%;
    top: 86%;
  }

  .play-btn {
    width: 5%;
    height: 4%;
  
    left: 17.5%;
    top: 62%;  
  }

  .pause-btn {
    width: 5%;
    height: 4%;
  
    left: 25%;
    top: 62%;  
  }

  .stop-btn {
    width: 5%;
    height: 4%;
  
    left: 32.6%;
    top: 62%;  
  }

  .skip-fwd-btn {
    width: 5%;
    height: 4%;
  
    left: 40%;
    top: 62%;  
  }

  .skip-back-btn {
    width: 5%;
    height: 4%;
  
    left: 10.3%;
    top: 62%;  
  }

  .spiky-dj-image {
    background-image: url(/static/media/spiky_dj_image.b9ae7689.webp);

    width: 90%;
    height: 50%;
    top: 15%;
    left: 8%;  
  }  

  .manifesto-container {
    width: 90%;
    height: 50%;
    top: 15%;
    left: 8%;  
  }  

  .spiky-dj-text {
    width: 10%;
    height: 5%;
    top: 4%;
    left: 34.5%;    
  }

  .console {
    background-image: url(/static/media/console.876a96c0.webp);
  }
}

